body {
  font-family: 'Rubik', sans-serif;
}

.w-100 {
  width: 100px;
}

.w-200 {
  width: 200px;
}

.w-300 {
  width: 300px;
}

.w-400 {
  width: 400px;
}

.w-500 {
  width: 500px;
}

.w-600 {
  width: 600px;
}

.w-700 {
  width: 700px;
}

.w-800 {
  width: 800px;
}

.w-900 {
  width: 900px;
}

.h-200 {
  height: 200px;
}

.h-300 {
  height: 300px;
}

.h-400 {
  height: 400px;
}

.h-500 {
  height: 500px;
}

.h-600 {
  height: 600px;
}

.h-700 {
  height: 700px;
}

.h-800 {
  height: 800px;
}

.h-900 {
  height: 900px;
}

.custom-label input:checked + svg {
  display: block !important;
}



.tipimage {
  position: relative;
  width: 100%;
  text-align: center;
  height: 300px;
}

.tipimage .newtip {
  position: absolute;
  right: 0;
  font-size: 15px;
  line-height: 30px;
  padding: 6px;
  font-weight: 500;
  background-color: #ffee88;
}

.tipimage img {
  width: 100%;
  object-fit: cover;
  height: 300px;
}

@media (min-width: 1024px) {
  .tipimage {
    height: 200px;
  }

  .tipimage img {
    height: 200px;
  }
}

.tipDetailImage {
  position: relative;
  height: 400px;
  width: 100%;
}

.tipDetailImage img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.tipDetailImage img.top {
  object-position: top;
}

.tilDetailTitle {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background-color: #CDCDCDAA;
}

.filters-content .custom-label {
  margin-top: 15px;
}

.filters-content h4 {
  font-weight: 500;
  margin-bottom: 12px;
  border-bottom: 1px solid black;
  height: 60px;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
}

.button-mid {
  min-width: 200px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  background-color: #ffee88;
  border-bottom: 1px solid black;
  box-decoration-break: clone;
}

.text-red {
  color: #e71b46; 
}

.button-red {
  min-width: 200px;
  height: 40px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  background-color: #e71b46;
  border-bottom: 1px solid black;
}

.button-semi {
  min-width: 100px;
  height: 40px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  background-color: #ffee88;
  border-bottom: 1px solid black;
}

.cancel-button {
  min-width: 100px;
  height: 40px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  border-bottom: 1px solid black;
}

.button-small {
  min-width: 40px;
  height: 40px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  background-color: #ffee88;
  border-bottom: 1px solid black;
}

.button.filters {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}

.button.filters img {
  width: 30px;
  display: inline-block;
}

.filter-overlay {
  position: static;
}

.input-container {
  height: 38px;
  border: 1px solid black;
  position:relative;
}

.input-container input {
  height:33px;
  margin:0;
  border: none;
  padding-top: 5px;
  font-weight: 500;
}

.input-container img {
  position:absolute;
  bottom:10px;
  right:15px;
  width:20px;
  height:20px;
}


.dropdown > div {
  background: white;
  border-color: black;
  border-radius: 0px;
}

.footer .mail {
  text-decoration: underline;
}

.footer .feedback svg {
  margin-bottom: 3px;
}

.footer .mail:hover {
  color: #555;
}

.footer > div {
  
}

.footer  {
  font-size: 16px;
}

.footer .logo span {
  font-size: 22px;
}

.footer .logo img {
  display: inline-block;
  width: 150px;
}

.footer * {
  vertical-align: bottom;
}

.footer > div div {
  display: inline-block;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  margin-left: 10px;
}


.feedbackImg {
  width: 570px;
}

.feedback .text {
  text-decoration: underline;
  font-size: 22px;
}

 .feedback .image img {
  width: 150px;
}


.feedback h2 {
  font-size: 36px;
}

.feedback p {
  font-size: 16px;
  margin-bottom: 32px;
}

.feedback textarea {
  margin-bottom: 32px;
}

.flex-2 {
  flex: 2 1 0;
}

.login .image {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.logintitle {
  font-weight: 300;
  margin-top: 10px;
}


.loginsubmit {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}

.loginsubmit img {
  display: inline;
}

.tags {
  margin-left: -0.2rem;
  margin-right: -0.2rem;
}

.tag {
  background-color: #dedede;
  color: #000;
  border-radius: 5px;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0.2rem;
  height: 1.8rem;
  font-size: 0.9rem;
}

.contactButton {
  background-color: #dedede;
  color: #000;
  border-radius: 5px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 3rem;
  font-size: 1.0rem;
}

.tag.selected {
  background-color: #ffee88;
  color: black;
}

.support {
  text-align: center;
  color: #888;
  height:35px;
  padding-top:5px;
}

.support span {
  background-image: linear-gradient(to bottom,transparent 40%,#EEE 40%,#EEE 70%,transparent 70%)
}

.support.highlight span {
  color: black;
  background-image: linear-gradient(to bottom,transparent 40%,#fdeb92 40%,#fdeb92 70%,transparent 70%)
}

.htmlcontent p {
  margin-bottom: 20px;
}

.interestedInClara {
  display: block;
  float: left;
}

.interestedInClara a {
  float: right;
  text-decoration: underline;
}

.htmlcontent ul {
  margin-left: 1rem;
}

.htmlcontent a {
  color: #d6a51c;
  text-decoration: underline;
}

.htmlcontent h1 {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.htmlcontent h2 {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.htmlcontent h3 {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.float-left {
  float: left;
}


.logoicon {

  max-height: 64px;
}

.yellow {
  color: #d6a51c;
}

ol,
ul {
  list-style: disc;
  padding-left: 1rem;
}

.spinner {
  position: relative;
  pointer-events: none;
  height: 200px;
}

.spinner::after {
  content: '';
  position: absolute !important;
  top: calc(50% - (1em / 2));
  left: calc(50% - (5em / 2));
  display: block;
  width: 5em;
  height: 5em;
  border: 6px solid currentColor;
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  animation: spinAround 500ms infinite linear;
}

.test-disclaimer {
  color: #ba1c00;
}

@keyframes spinAround {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.help svg {
  width: 1rem;
  cursor: pointer; 
}

.dropHelp {
  position: absolute;
  top: 6px;
  right: 66px;
}

.dropHelp svg {
  width: 1.2rem;
  cursor: pointer;
  color: #aaa;
}